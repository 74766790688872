import { Link, useNavigate } from 'react-router-dom'

import { Logotipo } from '../usuario/Logotipo'

import Offcanvas from 'react-bootstrap/Offcanvas'
import BtnSaldo from '../usuario/BtnSaldo'
import { useContext, useEffect, useState } from 'react'
import { ContextoGeral } from '../../contextos/ContextoGeral'

import logotipoMapp from './../../assets/logo-mapp.png'

export default function MenuLateral(props) {
    const { dados } = useContext(ContextoGeral)

    const [menuItems, setMenuItems] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        let arr = [{
            index: 0,
            rota: '/app/home',
            titulo: 'Inicio',
            ativo: true
        }, {
            index: 1,
            rota: '/app/testarStatus',
            titulo: 'Testar Status',
            ativo: true
        }, {
            index: 2,
            rota: '/app/indicarCliente',
            titulo: 'Indique um cliente',
            ativo: true
        }, {
            index: 3,
            rota: '/app/relatorios',
            titulo: 'Relatórios',
            ativo: true
        }, {
            index: 4,
            rota: '/app/dadosUsuario',
            titulo: 'Dados adicionais',
            ativo: true
        }, {
            index: 6,
            rota: '/app/configuracoes',
            titulo: 'Configurações',
            ativo: true
        }]

        if (dados.modulos != null) {
            if (dados.modulos.indicacao.ativo != 'S') {
                arr = arr.filter((item, i) => {
                    if (item.titulo == 'Indique um cliente') {
                        item.ativo = false
                    }
                    return item
                })
            }
        }

        setMenuItems(arr)
    }, [])

    return (
        <Offcanvas
            style={{ borderLeft: '0.25rem solid var(--primaria-pura)' }}
            placement='end'
            show={props.show}
            onHide={() => props.setShow(false)}
        >
            <Offcanvas.Header>
                <div style={{ flex: 1 }}>
                    <Logotipo aux={() => props.setShow(false)} />
                    <BtnSaldo />
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    {menuItems.map((item, id) => {
                        let classe = "nav-link text-start my-0 w-100"
                        if (props.ativo == item.index) {
                            classe = "nav-link text-start w-100 active"
                        }

                        return <button
                            key={item.index}
                            className={classe}
                            id="v-pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-home"
                            type="button"
                            role="tab"
                            style={{
                                backgroundColor: (props.ativo == item.index) ? "var(--primaria)" : "none",
                                color: (props.ativo == item.index) ? "" : "var(--primaria)"
                            }}
                            aria-controls="v-pills-home"
                            aria-selected="true"

                            onClick={() => {
                                if (item.ativo) {
                                    props.setShow(false)
                                    navigate(item.rota)
                                } else {
                                    if (!!window.AndroidInterface) {
                                        if (typeof window.AndroidInterface.showAndroidVersion === "function") {
                                            window.AndroidInterface.showAndroidVersion("Você não possui este módulo!")
                                        }
                                    }
                                }
                            }}
                        >
                            {item.titulo}
                        </button>
                    })}
                </div>
                {dados.config?.exibicao ? (
                    <div
                        className='d-flex flex-column'
                        onClick={() => {
                            if (!!window.AndroidInterface) {
                                if (typeof window.AndroidInterface.redirectLink === "function") {
                                    window.AndroidInterface.redirectLink('https://mappsistemas.com.br/')
                                }
                            }
                        }}
                    >
                        <small className='small'>
                            <small>
                                Desenvolvido por:
                            </small>
                        </small>
                        <img style={{ paddingBottom: '0.5rem', width: '40%' }} alt="" src={logotipoMapp} />
                    </div>
                ) : ('')}
                <div className='d-flex mt-3 justify-content-end' style={{
                    position: 'absolute',
                    bottom: '1.5rem',
                    right: '1.5rem'
                }}>
                    <button onClick={() => { props.setShow(false) }} className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}