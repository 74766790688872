import { useEffect, useState } from 'react'
import { Rotas } from './Rotas'
import { useNavigate } from "react-router-dom"

import './App.css'
import Boundary from './Boundary'

function App() {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)

  const debug = window.location.hostname == "localhost"

  return <div className="App">
    {debug && <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      className="position-absolute"
      style={{
        width: "300px",
        background: "white",
        boxShadow: "0 0 10px black",
        top: "50%",
        left: visible ? 0 : "-270px",
        borderRadius: "0 1rem 1rem 0",
        padding: "1rem",
        transition: "left 0.5s ease",
        zIndex: 10000
      }}
    >
      <h5>DEBUG MODE</h5>
      <div className="d-flex gap-2">
        <button style={{ fontSize: "0.5rem" }} onClick={() => navigate("/playground")}>Playground</button>
        <button style={{ fontSize: "0.5rem" }} onClick={() => navigate("/?dominio=mototaxionline.srv.br")}>Tela Inicial</button>
      </div>
    </div>}
    <Boundary>
      <Rotas />
    </Boundary>
  </div>
}

export default App