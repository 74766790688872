import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { ContextoGeralProvider } from "./contextos/ContextoGeral"
import * as ServiceWorker from "./serviceWorker"
import adapter from "webrtc-adapter"

// ESTILIZAÇÃO GLOBAL
import "bootstrap/dist/css/bootstrap.min.css"
import "./index.scss"

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <ContextoGeralProvider>
      <App />
    </ContextoGeralProvider>
  </BrowserRouter>
)

ServiceWorker.unregister()