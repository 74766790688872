import { useState, useContext } from "react"
import { Alert, Backdrop, Button, ButtonGroup, Card, Chip, CircularProgress, Collapse } from "@mui/material"

import icon from "./../../assets/icones/relogio.svg"
import Status from "../uteis/Status"
import { Col, Row } from "react-bootstrap"

import ServicoAPI from "./../../servicos/ServicoAPI"
import { ContextoGeral } from "../../contextos/ContextoGeral"
import { MdWarning } from "react-icons/md"
import { ContentCopy, LocalPhone } from "@mui/icons-material"
import { chamarTel } from "../uteis/telefone"

export default function CardAcertado({ servico }) {
	const urgente = servico["s.urgente"] == "S"
	const economico = servico["s.freteExpresso"] != ""

	const [show, setShow] = useState(false)
	const [showAcertar, setShowAcertar] = useState(true)

	function onSuccess() {
		setShowAcertar(false)
	}

	return <Card className={`p-3 bgGradientGrey ${urgente && 'text-danger'}`}>
		{urgente && <UrgentBar />}
		{economico && <EconomicBar />}

		<div className="d-flex justify-content-between">
			<div className="d-flex align-items-center">
				<h4 style={{ fontWeight: 800 }} className="me-2 mb-0">
					#{servico['s.id']}
				</h4>
				{servico['s.ifood'] && <Chip color="error" label="iFood" size="small" />}
			</div>
			<div>
				<Status texto={servico?.filtroLegenda ?? 'CONCLUIDO'} danger={urgente} />
			</div>
		</div>

		{servico["nome"] && <div className="mb-2 d-inline-block text-truncate small" style={{
			color: 'var(--primaria-darkest)'
		}}>
			<Chip label={<><b>Solicitante:</b> {servico["nome"]}</>} />
		</div>}

		<Enderecos servico={servico} />

		<DetalhesAcertado servico={servico} />

		<div className="d-flex gap-3 mt-3 g-1">
			{servico["so.nome"] && servico["so.nome"] != "-" && <div className="text-truncate w-100">
				<Status danger={urgente} texto={servico["so.nome"]} fill={true} truncate pop />
			</div>}

			{servico["so.empresa"] && servico["so.empresa"] != "-" && <div className="text-truncate w-100">
				<Status danger={urgente} texto={servico["so.empresa"]} fill={true} truncate pop />
			</div>}

			{servico["so.nomeFantasia"] && servico["so.nomeFantasia"] != "-" && <div className="text-truncate w-100">
				<Status danger={urgente} texto={servico["so.nomeFantasia"]} fill={true} truncate pop />
			</div>}
		</div>
		<Collapse in={servico["fs.dataAcertoMotoboy"] == "" && showAcertar} unmountOnExit>
			<Button
				variant="contained"
				className="mt-3"
				onClick={() => setShow(true)}
				color="success"
				fullWidth
			>
				Acertado
			</Button>
		</Collapse>
		<Collapse in={show}>
			<CardConfirmar id={servico['s.id']} setShow={setShow} onSuccess={onSuccess} />
		</Collapse>
	</Card>
}

function UrgentBar() {
	return <div className="bg-danger mb-2 text-light" style={{
		margin: "-1rem",
		padding: "0.5rem 1rem"
	}}>
		<div className="d-flex align-items-center gap-2">
			<img src={icon} style={{
				height: "1.25rem",
				filter: "brightness(10)"
			}} alt="" />
			Serviço urgente
		</div>
	</div>
}

function EconomicBar() {
	return <div className="bg-info mb-2 text-light" style={{
		margin: "-1rem",
		padding: "0.5rem 1rem"
	}}>
		<div className="d-flex align-items-center gap-2">
			<MdMonetizationOn size={24} />
			Serviço econômico
		</div>
	</div>
}

function Enderecos({ servico }) {
	return <>
		<Row className="mt-2">
			<Col className="d-inline-block text-truncate" style={{
				color: 'var(--primaria-darkest)'
			}}>
				{servico.enderecos[0]['e.rua']}
			</Col>
		</Row>
		<Row>
			{servico.enderecos?.length == 2
				? <Col className="d-inline-block text-truncate my-1" style={{
					color: 'var(--primaria-darkest)'
				}}>
					{servico.enderecos[1]['e.rua']}
				</Col>
				: <>
					<div xs="4" className="d-flex align-items-center opacity-50 w-100" style={{
						color: 'var(--primaria-darkest)'
					}}>
						<div className="small d-flex gap-2 align-items-center text-truncate">
							{(servico.enderecos?.length - 1) > 1
								? <>
									<div>
										Endereços
									</div>
								</> : ''}
						</div>
					</div>
					<div
						xs="8"
						className="d-flex align-items-center justify-content-end opacity-50"
					>
						{servico['s.retorno'] == 'S' && <div
							style={{ color: 'var(--primaria-darkest)' }}
							className="d-flex align-items-center gap-1 small">
							<MdUndo size="22" />
							Retorno ao 1º pontovan
						</div>}
					</div>
				</>
			}
		</Row>
	</>
}

export function DetalhesAcertado({ servico }) {
	return <Card className="text-white p-2 small" sx={(t) => ({
		background: t.palette.primary.main
	})}>
		{servico.farmacia
			? <>
				{servico.farmacia.opcao && <div>Opção: {servico.farmacia.opcao}</div>}
				<div>{servico.farmacia.cartao}</div>
				<div>{servico.farmacia.produtos}</div>
				<div>{servico.farmacia.valorProdutos}</div>
				<div>{servico.farmacia.troco}</div>
				<div>{servico.farmacia.totalReceber}</div>
				{servico.farmacia.farmaciaVendedor && servico.farmacia.farmaciaVendedor.nome  && <div>
					<hr className="my-0" />
					<div>Vendedor:</div>
					{servico.farmacia.farmaciaVendedor.nome && <div>{servico.farmacia.farmaciaVendedor.nome}</div>}
					{servico.farmacia.farmaciaVendedor.email && <div>{servico.farmacia.farmaciaVendedor.email}</div>}
				</div>}

				{servico.farmacia.ponto2tel && <Telefones tels={servico.farmacia.ponto2tel} />}
				{servico?.farmaciaVendedor && servico?.farmaciaVendedor?.nome && <div>Vendedor: {servico.farmaciaVendedor.nome}</div>}
				{servico.farmacia.trazer && servico.farmacia.trazer != "" && <div>{servico.farmacia.trazer}</div>}
				{servico.farmacia.obs && servico.farmacia.obs != "" && <div>Obs: {servico.farmacia.obs}</div>}
			</>
			: <>
				{servico["fs.opcao"] && <div>Opção: {servico["fs.opcao"]}</div>}
				<div>{servico["fs.cartao"]}</div>
				<div>{servico["fs.produtos"]}</div>
				<div>{servico["fs.valorProdutos"]}</div>
				<div>{servico["fs.troco"]}</div>
				<div>{servico["fs.totalReceber"]}</div>

				{servico["vendedor.nome"] && <div>Vendedor: {servico["vendedor.nome"]}</div>}
				{servico["ponto2tel"] && <Telefones tels={servico["ponto2tel"]} />}
				{servico["fs.trazer"] && servico["fs.trazer"] != "" && <div>{servico["fs.trazer"]}</div>}
				{servico["fs.obs"] && servico["fs.obs"] != "" && <div>Obs: {servico["fs.obs"]}</div>}
			</>}
	</Card>
}

function CardConfirmar({ id, setShow, onSuccess }) {
	const { dados } = useContext(ContextoGeral)

	const [success, setSucesso] = useState(false)
	const [loading, setLoading] = useState(false)
	const [alerter, setAlerter] = useState({
		show: false,
		msg: "",
		color: "primary"
	})

	return <Card className="p-3 mt-3">
		<div className="text-center">
			<MdWarning size={55} />
		</div>
		<div className="mb-2">
			Confirma acertar o serviço <b>{id}</b>?
		</div>
		<Collapse in={!success}>
			<div className="d-flex gap-3">
				<Button variant="contained" onClick={() => {
					setLoading(true)
					setAlerter({ show: false, msg: "", color: "error" })
					ServicoAPI.acertar(dados, id).then(res => {
						if (res == 1) {
							setAlerter({ show: true, msg: "Sucesso!", color: "success" })
							onSuccess()
							setSucesso(true)
						} else if (res == "token-invalido")
							setAlerter({ show: true, msg: "Token inválido!", color: "error" })
						else
							setAlerter({ show: true, msg: res, color: "error" })
					}).catch(rej => {
						//
					}).finally(() => {
						setLoading(false)
					})
				}} color="success" fullWidth>Sim</Button>
				<Button variant="contained" onClick={() => setShow(false)} color="error" fullWidth>Não</Button>
			</div>
		</Collapse>
		<Collapse in={loading}>
			<div className="text-center mt-3">
				<CircularProgress />
			</div>
		</Collapse>
		<Collapse in={alerter.show}>
			<Alert severity={alerter.color} className="mt-3">
				{alerter.msg}
			</Alert>
		</Collapse>
	</Card>
}

function Telefones({ tels }) {
	return <div>
		<hr className="my-0" />
		<div>Telefones:</div>
		{tels.map((tel, i) => <div key={i} className="d-flex justify-content-between align-items-center gap-2 my-2">
			<div>
				<LocalPhone style={{ height: "20px", width: "20px", marginRight: "0.5rem" }} />
				<span id={"tel-" + i}>
					{tel}
				</span>
			</div>
			<div>
				<ButtonGroup variant="contained">
					<Button color="info" onClick={() => chamarTel(tel)}>
						<LocalPhone style={{ height: "20px", width: "20px" }} />
					</Button>
					<Button color="info" onClick={() => {
						try {
							navigator.clipboard.writeText(document.querySelector("#tel-" + i).textContent)
								.then(() => AndroidInterface.showAndroidVersion("copiado"))
								.catch(() => AndroidInterface.showAndroidVersion("falha"))
						} catch {
							if (AndroidInterface)
								AndroidInterface.showAndroidVersion("falha, não está em ambiente seguro!")
							else alert("falha, não está no android")
						}
					}}>
						<ContentCopy style={{ height: "20px", width: "20px" }} />
					</Button>
				</ButtonGroup>
			</div>
		</div>)}
	</div>
}