import { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import TopoMenu from '../../../componentes/gerais/TopoMenu'
import AltMax from '../../../componentes/gerais/AltMax'
import MenuLateral from '../../../componentes/gerais/Menu'
import { Col, Form, Row } from 'react-bootstrap'

import { styled, alpha } from '@mui/material/styles';

import { format, parse } from 'date-fns'

import { Alert, Backdrop, Button, Card, CircularProgress, Collapse, Divider, FormControl, FormLabel, IconButton, InputLabel, Menu, MenuItem, Popper, Select, Zoom } from '@mui/material'

import CardEndereco from '../../../componentes/form/CardEndereco'
import ServicoAPI from '../../../servicos/ServicoAPI'
import { ContextoGeral } from '../../../contextos/ContextoGeral'
import { Spinner } from '../../../componentes/Spinner'
import { configAnim } from '../../../servicos/config/configAnim'
import MensagemApp from "../../../servicos/config/MensagemApp.js"
import Refresher from '../../../componentes/uteis/Refresher'
import CheckPermission from '../../../servicos/config/CheckPermission.js'
import { MdCall, MdChangeCircle, MdContentCopy, MdExpandMore, MdExplore, MdLocationOn, MdMonetizationOn, MdPhone, MdSocialDistance, MdTimer } from 'react-icons/md'
import { FaWhatsapp } from 'react-icons/fa'


import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import icon from "./../../../assets/icones/relogio.svg"
import AlertDialog, { BootClickAlert, FakeGpsAlert } from '../../../componentes/gerais/Dialog'
import FakeGPSBlock from '../../../componentes/uteis/FakeGPSBlock'
import { print, verificarBootClick, verificarFakeGPS } from '../../../servicos/Uteis'
import zIndex from '@mui/material/styles/zIndex.js'
import { DetalhesAcertado } from '../../../componentes/servicos/CardAcertado.jsx'
import { chamarTel, chamarWhats } from '../../../componentes/uteis/telefone.jsx'
import { BackHand, Call, Check, Close, CopyAll, Edit, Explore, QrCode, WhatsApp } from '@mui/icons-material'

export default function ServicoDetalhes() {
	const { id } = useParams()

	const navegar = useNavigate()

	const params = useParams()

	const [servico, setServico] = useState({})
	const [menu, setMenu] = useState(false)

	const [carregando, setCarregando] = useState(true)
	const [spinMarcador, setSpinMarcador] = useState(false)
	const [showAction, setShowAction] = useState(true)

	const [exibirFakeGPS, setExibirFakeGPS] = useState(false)
	const [exibirBootClick, setExibirBootClick] = useState(false)

	const [endAtivo, setEndAtivo] = useState({})

	const [backdrop, setBackdrop] = useState(false)

	const [textoFinalizar, setTextoFinalizar] = useState('')

	const [BD, setBD] = useState(false)
	const [texto, setTexto] = useState('')
	const [BDCancel, setBDCancel] = useState(false)
	const [BDCancelAcerto, setBDCancelAcerto] = useState(false)
	const [cancelOpt, setCancelOpt] = useState('')
	const [cancelVal, setCancelVal] = useState('')
	const [cancelValido, setCancelValido] = useState(true)
	const [cancelarAcerto, setCancelarAcerto] = useState(false)
	const [sistemaFarmacia, setSistemaFarmacia] = useState(false)
	const [showDetails, setShowDetails] = useState(false)
	const [isClicking, setIsClicking] = useState(false)
	const [corDinamica, setCorDinamica] = useState(false)

	const [perguntaProximo, setPerguntaProximo] = useState("")

	const { dados, setDados } = useContext(ContextoGeral)

	const handleClose = (e) => {
		const BACKDROP = document.querySelector(`[data-id="backdrop"]`);
		const CURRENT_TARGET = e.target;

		if (BACKDROP?.children?.[0] == CURRENT_TARGET) {
			if (!spinMarcador) {
				setBackdrop(false)
			}
		}
	}

	const handleCloseBD = () => {
		setBD(false)
	}

	async function confirmarEndereco(data) {
		setEndAtivo(data)
	}

	function verGps() {
		if (window.AndroidInterface)
			if (endAtivo.laEnd && endAtivo.loEnd)
				window.AndroidInterface.abrirMaps(`${endAtivo.laEnd},${endAtivo.loEnd}`)
			else
				window.AndroidInterface.showAndroidVersion("")
		else
			console.error('Não está no Android')
	}

	async function verificarAntifraude() {
		if (window.AndroidInterface?.isFakeGPS && window.AndroidInterface?.recoverVersion && window.AndroidInterface.recoverVersion() > 100.80) {
			if (dados?.configFakeGPS?.opcao != 'NF' && await window.AndroidInterface.isFakeGPS()) {
				setExibirFakeGPS(true)
			}

			if (dados?.configBootClick && (dados?.configBootClick?.opcao != 'NF' && verificarBootClick())) {
				setExibirBootClick(true)
			}
		}
	}

	async function buscarDetalhesServico() {
		let retorno = await ServicoAPI.buscarServico(id, dados)

		if (retorno.resultado) {
			if (retorno.aux?.dadosProf) {
				if (retorno.aux.dadosProf?.status == "OL" && dados.statusOnline == false) {
					let json = dados
					json.statusOnline = true
					setDados(json)
				}
			}

			if (retorno?.aux?.dadosProf?.corDinamica)
				setCorDinamica(retorno.aux.dadosProf.corDinamica == "S")

			if (retorno?.aux?.dadosProf?.exibeBotaoAcertar == "S")
				setCancelarAcerto(true)

			if (retorno?.aux?.dadosProf?.sistemaFarmacia == "S")
				setSistemaFarmacia(true)

			if (retorno?.dados) {
				if (retorno.dados[0].perguntaProximo)
					setPerguntaProximo(retorno.dados[0].perguntaProximo)

				setServico(retorno.dados[0])
			} else setServico({})
		}

		setCarregando(false)
	}

	async function marcarPonto() {
		handleCloseBD()
		setIsClicking(false)

		let bloqueio = false

		try {
			if (window.AndroidInterface?.isFakeGPS && window.AndroidInterface?.recoverVersion && window.AndroidInterface.recoverVersion() > 100.80) {
				if (dados?.configFakeGPS?.opcao != 'NF' && await window.AndroidInterface.isFakeGPS()) {
					setExibirFakeGPS(true)
					bloqueio = dados?.configFakeGPS?.opcao == "B"
				}

				if (dados?.configBootClick && (dados?.configBootClick?.opcao != 'NF' && verificarBootClick())) {
					setExibirBootClick(true)

					if (!bloqueio)
						bloqueio = dados?.configBootClick?.opcao == "B"
				}

				if (bloqueio) return
			}

			setSpinMarcador(true)

			let statusPermissao = true

			if (statusPermissao && !CheckPermission.checkLocationAvailable()) {
				statusPermissao = false

				if (window.AndroidInterface)
					window.AndroidInterface.showPermissaoLocalizacao(MensagemApp.msgPermissaoGPS)
			}

			if (statusPermissao && !CheckPermission.checkLocationEnabled()) {
				statusPermissao = false
				if (window.AndroidInterface)
					window.AndroidInterface.showHabilitarLocalizacao(MensagemApp.msgHabilitarGPS)
			}

			if (statusPermissao) {
				let location = {
					latitude: 0,
					longitude: 0
				}

				if (window.AndroidInterface)
					location = JSON.parse(window.AndroidInterface.getLocation())

				if ((location == '') || (location.latitude == 0 || location.longitude == 0)) {
					if (typeof window.AndroidInterface !== 'undefined') {
						window.AndroidInterface.showAndroidVersion(MensagemApp.msgErroLaLo)
					} else {
						alert(MensagemApp.msgErroLaLo)
					}

				} else {
					let retorno = await ServicoAPI.marcarChegada(dados, endAtivo, location)

					if (retorno?.status == 200) {
						if (retorno.data?.mensagem) {
							if (!!window.AndroidInterface) {
								window.AndroidInterface.showAndroidVersion(retorno.data.mensagem)
							} else {
								alert(retorno.data.mensagem)
							}
						} else {
							if (!!window.AndroidInterface) {
								window.AndroidInterface.showAndroidVersion(MensagemApp.msgErroApi)
							} else {
								alert(MensagemApp.msgErroApi)
							}
						}

					} else if (retorno?.status == 404 || retorno?.status == 400) {
						if (!!window.AndroidInterface) {
							window.AndroidInterface.showAndroidVersion(retorno.data?.mensagem)
						} else {
							alert(retorno.data?.mensagem)
						}

					} else {
						if (!!window.AndroidInterface) {
							window.AndroidInterface.showAndroidVersion(MensagemApp.msgErroApi)
						} else {
							alert(MensagemApp.msgErroApi)
						}
					}
				}
			}
		} catch {
			console.error(MensagemApp.funcIndisponivel)
		}

		setSpinMarcador(false)
		setBackdrop(false)
		setCarregando(true)
	}

	async function acao() {
		setIsClicking(true)
		try {
			let bloqueio = false

			if (window.AndroidInterface?.isFakeGPS && window.AndroidInterface?.recoverVersion && window.AndroidInterface.recoverVersion() > 100.80) {
				if (dados?.configFakeGPS?.opcao != 'NF' && await window.AndroidInterface.isFakeGPS()) {
					setExibirFakeGPS(true)
					bloqueio = true
				}

				if (dados?.configBootClick && (dados?.configBootClick?.opcao != 'NF' && verificarBootClick())) {
					setExibirBootClick(true)

					bloqueio = true
				}

				if (bloqueio) return
			}

			if (endAtivo.modo) {
				navegar(`/app/servicos/${id}/finalizar`, {
					state: endAtivo
				})
			} else {
				setTexto('Confirma sua chegada?')
				setBD(true)
			}
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		if (carregando === true) {
			verificarAntifraude()
			buscarDetalhesServico()
		}
	}, [carregando])

	useEffect(() => {
		if (Object.keys(servico).length != 0) {
			setCarregando(false)
		}
	}, [servico])

	useEffect(() => {
		if (endAtivo?.idServico !== undefined) {
			setTextoFinalizar(endAtivo.label)
			setBackdrop(true)
		}
	}, [endAtivo])

	return (
		<motion.div
			className="h-100"
			initial={configAnim.initial}
			animate={configAnim.animate}
			exit={configAnim.exit}
			transition={configAnim.transition}
		>
			<TopoMenu voltar="/app/servicos" titulo={"DETALHES DO SERVIÇO " + params.id} setShow={setMenu} />

			{carregando
				? <div style={{
					height: 'calc(100vh - 3rem)'
				}}>
					<Spinner />
				</div>
				: <>
					{Object.keys(servico).length > 0
						? (<>
							<div
								style={{
									height: 'calc(100vh - 3.3rem)',
									overflow: 'auto'
								}}
							>
								<div className="d-flex bgPri align-items-center text-light justify-content-between py-2 px-2 mt-2">
									<h1 className='mb-0'>#{id}</h1>
									<div className="d-flex gap-2 align-items-center">
										{servico["s.dataHoraColetar"] != "0000-00-00 00:00:00"
											&& <div className="text-warning">Agendado</div>}
										<div>
											<div className={"fw800 " + (servico["s.dataHoraColetar"] != "0000-00-00 00:00:00"
												? "text-warning"
												: "")}>
												{format(parse(servico["s.dataHoraColetar"] != "0000-00-00 00:00:00"
													? servico["s.dataHoraColetar"]
													: servico['s.dataHora'], 'yyyy-MM-dd HH:mm:ss', new Date()), "dd/MM")}
											</div>
											<div className={"small text-center " + (servico["s.dataHoraColetar"] != "0000-00-00 00:00:00"
												? "text-warning"
												: "")} style={{ marginTop: '-0.5rem' }}>
												{format(parse(servico["s.dataHoraColetar"] != "0000-00-00 00:00:00"
													? servico["s.dataHoraColetar"]
													: servico['s.dataHora'], 'yyyy-MM-dd HH:mm:ss', new Date()), "HH:mm")}
											</div>
										</div>
									</div>
								</div>

								{servico['s.urgente'] == "S" && <div className='p-2 bg-danger d-flex align-items-center gap-2 text-light'>
									<img src={icon} alt="" style={{
										height: "1.25rem",
										filter: "brightness(10)"
									}} />
									Serviço Urgente
								</div>}

								{servico['s.freteExpresso'] != "" && <div className='p-2 bg-info d-flex align-items-center gap-2 text-light'>
									<MdMonetizationOn size={24} />
									Serviço Econômico
								</div>}

								{corDinamica && <div style={{ background: servico["so.corCliente"] }}>
									<h5 className="text-center text-white py-2" style={{
										textShadow: "0 2px 5px black, 0 1px 2px black, 0 -1px 6px black"
									}}>
										{servico["so.nome"]}
									</h5>
								</div>}

								<div className="d-flex justify-content-between px-2 mt-2 fw600">
									<div className="d-flex gap-2 align-items-center">
										{servico['s.descVeiculo'].toUpperCase()}
										<div className="opacity-50 d-flex align-items-center small">
											<Explore sx={{ fontSize: "22px" }} />{servico["sa.direcao"] && servico["sa.direcao"]}
										</div>
									</div>

									{servico['s.distancia'] && <div className='d-flex align-items-center gap-1'>
										<MdSocialDistance />
										<div>
											{servico['s.distancia'].replace('.', ',')}km
										</div>
									</div>}

									{servico['s.duracaoRota'] > 0 && <div className='d-flex align-items-center gap-1'>
										<MdTimer />
										<div>
											{servico['s.duracaoRota'] && (servico['s.duracaoRota'] / 60).toFixed(0)} min
										</div>
									</div>}
								</div>
								<div className='d-flex align-items-center flex-column mt-3'>
									<h1 className="text-success mb-0 fw800">
										{servico?.['s.valor'] && new Intl.NumberFormat('pt-BR', {
											style: 'currency',
											currency: 'BRL'
										}).format(servico['s.valor'])}
									</h1>
									<h3 className='text-success fw700'>
										{servico?.['s.valorProfissional'] && new Intl.NumberFormat('pt-BR', {
											style: 'currency',
											currency: 'BRL'
										}).format(servico['s.valorProfissional'])}
									</h3>
									<div className='small'>
										{servico['s.descPagamento'].toUpperCase()}
									</div>
								</div>

								<div className='d-flex justify-content-center opacity-75 small mt-4'>
									<div className='d-flex flex-column w-100 align-items-center' onClick={() => {
										setShowDetails(prev => !prev)
									}}>
										{servico.ifood && <Card className='mx-2 mb-3 p-3' style={{
											backgroundColor: "#ea1d2c",
											width: "calc(100% - 1rem)",
											color: "white"
										}}>
											<h2 className='text-center mb-3'>Integração Ifood</h2>
											<div className='lh-1 my-2'>
												Nº do pedido: <b>{servico.ifood.pedido}</b>
											</div>
											{servico.ifood.total && <div className='lh-1 my-2'>
												Total: <b>{new Intl.NumberFormat('pt-BR', {
													style: 'currency',
													currency: 'BRL'
												}).format(servico.ifood.total)}</b>
											</div>}
											{servico.ifood.troco && <div className='lh-1 my-2'>
												Troco: <b>{new Intl.NumberFormat('pt-BR', {
													style: 'currency',
													currency: 'BRL'
												}).format(servico.ifood.troco)}</b>
											</div>}
											<div className='lh-1 my-2'>
												forma de pagamento IFood: <b>{servico.ifood.forma_pagamento}</b>
											</div>
										</Card>}
										<div>Mais detalhes</div>
										<MdExpandMore />
									</div>
								</div>

								<Collapse
									className='px-2 bgPri text-light'
									style={{ marginBottom: '-0.5rem' }}
									in={showDetails}
								>
									<div className="d-flex justify-content-between small my-3">
										<div>
											<div className='text-truncate opacity-75 small'>Solicitante</div>
											<div className='text-truncate'>
												<strong>{servico['s.nomeSolicitante']}</strong>
											</div>
										</div>
										<div style={{ textAlign: 'end' }}>
											<div className='text-truncate opacity-75 small'>Nº do pedido</div>
											<div className='text-truncate'>
												<strong>{servico['s.numeroPedido']}</strong>
											</div>
										</div>
									</div>
									<div className="d-flex justify-content-between small my-3">
										<div>
											<div className='text-truncate opacity-75 small'>E-mail</div>
											<div className='text-truncate'>
												<strong>{servico['so.email']}</strong>
											</div>
										</div>
									</div>

									<div className="d-flex justify-content-between small my-3">
										<div>
											<div className='text-truncate opacity-75 small'>Nome</div>
											<div className='text-truncate'>
												<strong>{servico['so.nome']}</strong>
											</div>
										</div>
									</div>
									<div className="d-flex justify-content-between small my-3">
										<div>
											<div className='text-truncate opacity-75 small'>Empresa</div>
											<div className='text-truncate'>
												<strong>{servico['so.empresa']}</strong>
											</div>
										</div>
									</div>
									<div className="d-flex justify-content-between small my-3">
										<div>
											<div className='text-truncate opacity-75 small'>Nome Fantasia</div>
											<div className='text-truncate'>
												<strong>{servico['so.nomeFantasia']}</strong>
											</div>
										</div>
									</div>

									<div className="d-flex justify-content-between small my-3">
										<div>
											<div className='text-truncate opacity-75 small'>Telefone fixo</div>
											<div className='text-truncate'>
												<strong>{servico['so.telFixo']}</strong>
												<div className="d-flex justify-content-between">
													<IconButton onClick={() => chamarTel(servico['so.telFixo'])} style={{ color: "white" }}>
														<MdCall size={28} />
													</IconButton>
													<IconButton onClick={() => chamarWhats(servico['so.telFixo'])} style={{ color: "white" }}>
														<FaWhatsapp size={28} />
													</IconButton>
												</div>
											</div>
										</div>
										<div style={{ textAlign: 'end' }}>
											<div className='text-truncate opacity-75 small'>Telefone celular</div>
											<div className='text-truncate'>
												<strong>{servico['so.celular']}</strong>
												<div className="d-flex justify-content-between">
													<IconButton onClick={() => chamarTel(servico['so.celular'])} style={{ color: "white" }}>
														<MdCall size={28} />
													</IconButton>
													<IconButton onClick={() => chamarWhats(servico['so.celular'])} style={{ color: "white" }}>
														<FaWhatsapp size={28} />
													</IconButton>
												</div>
											</div>
										</div>
									</div>
									<div className="d-flex justify-content-between small my-3">
										<div>
											<div className='text-truncate opacity-75 small'>Centro de custo</div>
											<div className='text-truncate'>
												<strong>
													{servico['s.nomeCentroCusto'] ?? "-"}
												</strong>
											</div>
										</div>
									</div>

									{servico.cancelamento.status && <div className='mx-auto text-center w-75 my-3'>
										<Button
											fullWidth
											variant='contained'
											color="error"
											style={{ border: '3px solid white' }}
											onClick={() => {
												setBDCancel(true)
											}}>
											Cancelar serviço
										</Button>
									</div>}
								</Collapse>

								<Collapse in={sistemaFarmacia}>
									<div className="m-3 d-flex flex-column">
										<Button
											fullWidth
											variant='contained'
											color="error"
											onClick={() => {
												setBDCancelAcerto(true)
											}}>
											Cancelar Farmácia
										</Button>
									</div>
								</Collapse>
								<Collapse in={sistemaFarmacia}>
									<div className="m-3 d-flex flex-column mt-3">
										<DetalhesAcertado servico={servico} />
									</div>
								</Collapse>
								<Divider className="my-2" />
								<div>
									{servico && servico.enderecos && Array.isArray(servico.enderecos) && servico.enderecos.map((end, i) => {
										let desativado = false
										let dadosEnderecoAtivo = {
											idServico: id,
											modo: false,
										}

										let trocarPonto = false
										if (end?.['es.botaoCheguei']) {
											if (end['es.botaoCheguei'] != '') {
												dadosEnderecoAtivo = {
													...dadosEnderecoAtivo,
													label: end['es.botaoCheguei']
												}
											} else if (end['es.botaoFinalizar'] != '') {
												dadosEnderecoAtivo = {
													...dadosEnderecoAtivo,
													modo: true,
													label: end['es.botaoFinalizar']
												}
											} else {
												desativado = true
											}
										} else
											if (end['es.botaoFinalizar'] == '') {
												desativado = true
											} else {
												dadosEnderecoAtivo.modo = true
												dadosEnderecoAtivo.label = end['es.botaoFinalizar']
											}

										if (servico["proximoEndInformado"]) {
											if (end["e.id"] != servico["proximoEndInformado"])
												desativado = true
											else trocarPonto = true
										}

										return <Zoom
											key={'endereco' + i}
											in={Object.keys(servico).length > 0}
											style={{
												transitionDelay: (servico.enderecos?.length > 0) ? i * (1000 / Object.values(servico).length) : 0
											}}
										>
											<div className={desativado ? "opacity-50 mx-2" : 'mx-2'} style={{ position: "relative" }}>
												<CardEndereco
													id={i + 1}
													onClick={() => {
														dadosEnderecoAtivo.idAtivo = i + 1
														dadosEnderecoAtivo.endereco = end['e.rua']
														dadosEnderecoAtivo.observacao = end['e.responsavel']
														dadosEnderecoAtivo.idEndereco = end['e.id']
														dadosEnderecoAtivo.laEnd = end['e.la']
														dadosEnderecoAtivo.loEnd = end['e.lo']
														dadosEnderecoAtivo.tipoVeiculo = servico['s.mototaxi']
														dadosEnderecoAtivo.telefone = end['e.telefone']
														dadosEnderecoAtivo.contato = end['e.contato']
														dadosEnderecoAtivo.confirmaEntrega = end['e.clienteConfirma']
														setShowAction(desativado)

														confirmarEndereco(dadosEnderecoAtivo)
													}}
													retorno={servico['s.retorno'] == 'S' && (servico.enderecos.length - 1) == i}
													endereco={end['e.rua']}
													complemento={end['e.complemento']}
													tel={end['e.telefone']}
													contato={end['e.contato']}
													recebimento={end['e.endPontoReceber']}
													valor={servico['s.valor']}
													icone={desativado}
													trocarPonto={trocarPonto}
													cor={end["corPonto"]}
													confirmaEntrega={end["e.clienteConfirma"]}
												/>
												{trocarPonto && <IconButton className="position-absolute"
													style={{
														bottom: "-0.5rem",
														left: "1rem",
														background: "white",
														boxShadow: "0 0 15px -3px black"
													}}
													onClick={() => {
														setPerguntaProximo("Troque seu próximo ponto")
													}}
													fullWidth>
													<MdChangeCircle size={30} />
												</IconButton>}
											</div>
										</Zoom>
									})}
								</div>
								{servico['s.pontoReceber'] != "" && <div className='mx-2'>
									<Card className='p-2 small text-white' style={{ backgroundColor: "var(--primaria-darker)" }}>
										<h4 className='text-center fw700'>Observação</h4>
										{servico['s.pontoReceber']}
									</Card>
								</div>}
							</div>
						</>)
						: <>
							<Refresher
								setCarregando={setCarregando}
							/>
							<Alert severity="error" className="mx-3 mt-4 text-center">
								{MensagemApp.msgErroApi}
							</Alert>

						</>}
				</>}

			<FakeGpsAlert
				open={exibirFakeGPS}
				setOpen={setExibirFakeGPS}
				opcao={dados?.configFakeGPS?.opcao}
				onClose={() => {
					if (!exibirBootClick && isClicking) {
						if (endAtivo.modo) {
							navegar(`/app/servicos/${id}/finalizar`, {
								state: endAtivo
							})
						} else {
							setTexto('Confirma sua chegada?')
							setBD(true)
						}
					}
				}} />

			<BootClickAlert
				open={exibirBootClick}
				setOpen={setExibirBootClick}
				opcao={dados?.configBootClick?.opcao}
				onClose={() => {
					if (!exibirFakeGPS && isClicking) {
						if (endAtivo.modo) {
							navegar(`/app/servicos/${id}/finalizar`, {
								state: endAtivo
							})
						} else {
							setTexto('Confirma sua chegada?')
							setBD(true)
						}
					}
				}} />

			<MenuLateral show={menu} setShow={setMenu} />
			<FakeGPSBlock />

			<Backdrop
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={backdrop}
				onClick={handleClose}
				data-id="backdrop"
			>

				<AltMax>
					<div className="rodapeFloat">
						{endAtivo.confirmaEntrega && <>
							{endAtivo.confirmaEntrega == "S"
								? <Alert icon={false}>
									<div className='text-center'><Check /></div>
									<div className='text-center'>Cliente confirmou que poderá receber</div>
								</Alert>
								: <Alert severity="error" icon={false}>
									<div className='text-center'><Close className="mx-auto" /></div>
									<div className='text-center'>Cliente informou que <b>NÃO</b> poderá receber</div>
								</Alert>}
						</>}
						<CardEndereco
							id={endAtivo.idAtivo}
							endereco={endAtivo.endereco}
							observacao={endAtivo.observacao}
							icone
						/>

						{endAtivo.contato && endAtivo.contato != '' && <div className="w-100 gx-0 text-center mb-2" style={{ gap: '0.5rem' }}>
							<div style={{ fontSize: '0.66rem', marginBottom: '-0.35rem' }}>
								<b>CONTATO</b>
							</div>
							<div>
								{endAtivo.contato}
							</div>
						</div>}

						{!showAction && <div className="w-100">
							<div className="d-flex justify-content-between align-items-center">

								{endAtivo.telefone
									? endAtivo.laEnd && endAtivo.loEnd && <IconButton color="primary" onClick={verGps}>
										<MdLocationOn />
									</IconButton>
									: endAtivo.laEnd && endAtivo.loEnd && <Button
										className='mx-auto'
										variant="outlined"
										startIcon={<MdLocationOn />}
										onClick={verGps}
									>
										GPS
									</Button>}

								{endAtivo.telefone && <div className="gap-2">
									<small id="telLigacao">
										{endAtivo.telefone}
									</small>
									<IconButton color="primary" onClick={() => {
										setTimeout(() => {
											navigator.clipboard.writeText(document.querySelector("#telLigacao").textContent)
												.then(() => AndroidInterface.showAndroidVersion("copiado")).catch(() => AndroidInterface.showAndroidVersion("falha"))
										}, 5000)
									}}>
										<MdContentCopy />
									</IconButton>
								</div>}

								<div>
									{endAtivo.telefone && <IconButton color="primary" onClick={() => chamarWhats(endAtivo.telefone)}>
										<FaWhatsapp />
									</IconButton>}
									{endAtivo.telefone && <IconButton color="primary" onClick={() => chamarTel(endAtivo.telefone)}>
										<MdPhone />
									</IconButton>}
								</div>

							</div>

							<div className="col-12 mt-3">
								<div style={{ flex: 1 }}>
									{spinMarcador ? (
										<Button
											color='primary'
											className="w-100"
											variant='outlined'
											disabled
										>
											<div className='py-3'>
												<Spinner />
											</div>
										</Button>
									) : (
										<div onClick={() => {
											if (!dados.statusOnline) {
												if (typeof window.AndroidInterface !== 'undefined') {
													window.AndroidInterface.showAndroidVersion('Fique online')
												} else {
													alert('Fique online')
												}
											}
										}}>
											<Button
												onClick={() => {
													acao()
												}}
												style={{ fontSize: '1.2rem' }}
												className="w-100"
												color='primary'
												variant="contained"
												disabled={!dados.statusOnline}
											>
												<b className='text-light'>
													{textoFinalizar}
												</b>
											</Button>
										</div>
									)}
								</div>
							</div>
						</div>}

					</div>
				</AltMax>
			</Backdrop>

			<Backdrop
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
				open={BD}
			>
				<Card className="p-3">
					<h2>Confirme sua solicitação</h2>
					<p className='text-center small'>
						{texto}
					</p>
					<Row>
						<Col>
							<Button
								color="success"
								variant="contained"
								className="w-100"
								onClick={() => {
									marcarPonto()
								}}
							>
								Confirmar
							</Button>
						</Col>
						<Col>
							<Button
								color="error"
								variant="text"
								className="w-100"
								onClick={handleCloseBD}
							>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Card>
			</Backdrop>


			{!!servico.cancelamento && <Backdrop
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
				open={BDCancel}
			>
				<Card className='p-3 mx-3'>
					<h2 className='text-center'>Confirma cancelar?</h2>
					<div>
						O serviço <b>{id}</b> será cancelado
					</div>
					{!!servico.cancelamento.opcoes && servico.cancelamento.opcoes.length > 0
						? <Form.Select
							className={'mt-3' + (cancelValido ? '' : ' text-danger border-danger')}
							value={cancelOpt}
							onChange={(e) => {
								setCancelOpt(e.target.value)
								setCancelVal(e.target[e.target.selectedIndex].getAttribute('data-value'))
							}}
						>
							<option key="none" value={""} disabled>Selecione um motivo</option>
							{servico.cancelamento.opcoes.map((item, i) => {
								return <option key={item.label} value={item.label} data-value={item.value}>
									{item.label}
								</option>
							})}
						</Form.Select>
						: ''}
					<Row className='mt-3'>
						<Col>
							<Button
								color="success"
								variant="contained"
								className="w-100"
								onClick={async () => {
									if (cancelOpt == '') {
										setCancelValido(false)
									} else {
										setCancelValido(true)

										let retorno = await ServicoAPI.cancelarServico(dados, id, cancelVal, cancelOpt)
										if (!!window.AndroidInterface) {
											window.AndroidInterface.showAndroidVersion(retorno.mensagem)
										} else {
											alert(retorno.mensagem)
										}

										if (retorno.resultado) {
											navegar("/app/servicos")
										} else {
											setBDCancel(false)
											setCarregando(true)
										}
									}
								}}
							>
								Confirmar
							</Button>
						</Col>
						<Col>
							<Button
								color="error"
								variant="text"
								className="w-100"
								onClick={() => { setBDCancel(false) }}
							>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Card>
			</Backdrop>}
			<CancelAcerto open={BDCancelAcerto} onClose={() => {
				setBDCancelAcerto(false)
				setTimeout(() => navegar("/app/servicos"), 1000)
			}} />
			{servico.enderecos && <GerenciarColetas
				show={servico.exibirColetas == true && !carregando}
				enderecos={servico.enderecos}
				categoria={servico["s.mototaxi"]}
				retorno={servico["s.retorno"] == "S"}
				reload={() => setCarregando(true)}
			/>}
			{servico.enderecos && <ListaEnderecos
				show={perguntaProximo}
				pergunta={perguntaProximo}
				enderecos={servico.enderecos}
				reload={() => {
					setPerguntaProximo("")
					setCarregando(true)
				}}
			/>}
		</motion.div>
	)
}

function GerenciarColetas({ show, enderecos, retorno, categoria, reload }) {
	const { id } = useParams()
	const { dados } = useContext(ContextoGeral)

	function readQR() {
		if (window.AndroidInterface)
			AndroidInterface.ativaCamera('DC', Number(dados.idProf), Number(id), "", "S", "", "receberNumeroNota", dados.token)
		else
			alert("não está no celular")
	}

	useEffect(() => {
		window.finalizouEnvio = (res) => {
			try {
				res = JSON.parse(res)

				if (res?.resultado) {
					print(res.mensagem)
					reload()
				}
			} catch {
				alert(res.mensagem ?? "falha ao receber informações!")
			}
		}
	}, [])

	return <Backdrop in={show} unmountOnExit>
		<Card className="p-2 mx-3">
			<h4 className="text-center">Confirme suas coletas</h4>

			<Button
				color="success"
				onClick={readQR}
				className='mb-3'
				fullWidth
			>
				<QrCode /> Ler Código QR
			</Button>
			<div style={{
				maxHeight: "55vh",
				overflow: "auto"
			}}>
				{enderecos.map((endereco, i) => {
					if ((i != 0 && i != enderecos.length - (retorno ? 1 : 0)))
						return <BlocoColeta
							key={i}
							endereco={endereco}
							categoria={categoria}
							reload={reload}
						/>
				})}
			</div>
		</Card>
	</Backdrop>
}

function BlocoColeta({ key, endereco, reload, categoria }) {
	const navegar = useNavigate()
	const { id } = useParams()
	const { dados } = useContext(ContextoGeral)

	const [loading, setLoading] = useState(false)

	async function confirm(end) {
		setLoading(true)
		ServicoAPI.confirmarColeta(dados, {
			idProf: dados.idProf,
			idServico: id,
			idEndereco: end["e.id"]
		}).then(res => {
			if (res.resultado) {
				print(`ponto ${end["es.ponto"]} confirmado com sucesso`)
				setTimeout(() => reload(), 500)
			} else print("falha")
		})
	}

	async function report(end) {
		setLoading(true)

		let dadosEnderecoAtivo = {
			idServico: id,
			modo: false,
		}

		if (end?.['es.botaoCheguei']) {
			if (end['es.botaoCheguei'] != '')
				dadosEnderecoAtivo = {
					...dadosEnderecoAtivo,
					label: end['es.botaoCheguei']
				}
			else if (end['es.botaoFinalizar'] != '')
				dadosEnderecoAtivo = {
					...dadosEnderecoAtivo,
					modo: true,
					label: end['es.botaoFinalizar']
				}
		} else if (end['es.botaoFinalizar'] != '') {
			dadosEnderecoAtivo.modo = true
			dadosEnderecoAtivo.label = end['es.botaoFinalizar']
		}

		dadosEnderecoAtivo.idAtivo = end['es.ponto']
		dadosEnderecoAtivo.endereco = end['e.rua']
		dadosEnderecoAtivo.observacao = end['e.responsavel']
		dadosEnderecoAtivo.idEndereco = end['e.id']
		dadosEnderecoAtivo.laEnd = end['e.la']
		dadosEnderecoAtivo.loEnd = end['e.lo']
		dadosEnderecoAtivo.tipoVeiculo = categoria
		dadosEnderecoAtivo.telefone = end['e.telefone']
		dadosEnderecoAtivo.contato = end['e.contato']
		dadosEnderecoAtivo.confirmaEntrega = end['e.clienteConfirma']
		navegar(`/app/servicos/${id}/finalizar`, {
			state: dadosEnderecoAtivo
		})
	}

	async function clearAddress(id) {
		setLoading(true)
		ServicoAPI.limparEndereco(dados, {
			idProf: dados.idProf,
			idEndereco: id
		}).then(res => {
			if (res.resultado) {
				print(`ponto limpado com sucesso`)
				setTimeout(() => reload(), 500)
			} else
				alert("falha")
		})
	}

	return <Card key={key} className="p-2 mt-2">
		<CardEndereco
			id={endereco["es.ponto"]}
			endereco={endereco["e.rua"]}
			className="mb-2 py-2"
			observacao={endereco["e.responsavel"]}
			icone
		/>
		{loading
			? <div className="text-center">
				<CircularProgress />
			</div>
			: endereco["es.saida"]
				? <div
					className="d-flex justify-content-center text-danger align-item-center"
					style={{
						position: "relative",
						display: "flex",
						alignItems: "center",
						height: "2.5rem"
					}}
				>
					<Close />
					Coleta reportada

					<IconButton
						color="success"
						style={{
							position: "absolute",
							right: 0
						}}
						onClick={() => clearAddress(endereco["e.id"])}
					>
						<Edit />
					</IconButton>
				</div>
				: endereco["es.dataColeta"]
					? <div
						className="d-flex justify-content-center text-center align-item-center"
						style={{
							position: "relative",
							display: "flex",
							alignItems: "center",
							height: "2.5rem"
						}}
					>
						<Check />
						Coleta confirmada

						<IconButton
							color="success"
							style={{
								position: "absolute",
								right: 0
							}}
							onClick={() => clearAddress(endereco["e.id"])}
						>
							<Edit />
						</IconButton>
					</div>
					: <div className="d-flex gap-2">
						<Button
							color="success"
							variant="contained"
							startIcon={<Check fontSize='22px' />}
							className="lh-1"
							onClick={() => confirm(endereco)}
							fullWidth
						>
							Confirmar
						</Button>
						<Button
							color="error"
							variant="contained"
							startIcon={<Close fontSize='22px' />}
							className="lh-1"
							onClick={() => report(endereco)}
							fullWidth
						>
							Reportar
						</Button>
					</div>}
	</Card>
}

function ListaEnderecos({ show, pergunta, enderecos, reload }) {
	return <Backdrop in={show}>
		<Card className="p-2 mx-3">
			<h4 className="text-center">{pergunta}</h4>
			<div style={{ maxHeight: "80vh", overflowY: "auto" }}>
				{enderecos && enderecos.map((end, i) => {
					if (!end["es.saida"])
						return <AddressCapsule reload={reload} end={end} i={i} />
				})}
			</div>
		</Card>
	</Backdrop>
}

function AddressCapsule({ end, i, reload }) {
	const { id } = useParams()
	const navigate = useNavigate()
	const { dados, setDados } = useContext(ContextoGeral)

	const [show, setShow] = useState(false)

	return <Zoom in>
		<div className="m-2">
			<CardEndereco
				id={i + 1}
				className="py-2"
				onClick={() => setShow(true)}
				endereco={end['e.rua']}
				complemento={end['e.complemento']}
				tel={end['e.telefone']}
				contato={end['e.contato']}
				recebimento={end['e.endPontoReceber']}
				observacao={end["e.responsavel"]}
			/>
			<Backdrop in={show} sx={{
				zIndex: 10000
			}}>
				<Card className="p-3" style={{ width: "80vw" }}>
					<div className='lh-1 text-center mb-2'>
						Confirma ir para o endereço a seguir?
					</div>
					<CardEndereco
						id={i + 1}
						className="p-2"
						onClick={() => setShow(prev => !prev)}
						endereco={end['e.rua']}
						complemento={end['e.complemento']}
						tel={end['e.telefone']}
						contato={end['e.contato']}
						recebimento={end['e.endPontoReceber']}
						observacao={end["e.responsavel"]}
						icone
					/>
					<div className='d-flex gap-3 mt-3'>
						<Button
							variant='contained'
							onClick={() => {
								console.log("enviando notificação para rota")
								ServicoAPI.informarProxEntrega(dados, {
									idProf: dados.idProf,
									idServico: id,
									proximoEnd: end["e.id"]
								}).then(res => {
									setShow(false)
									if (res.resultado || res.data.resultado)
										reload()
									else if (AndroidInterface)
										AndroidInterface.showAndroidVersion(res.mensagem)
									else
										alert(res.mensagem)
								}).catch(rej => {
									console.log("ali: ", rej)
								})
							}}
							fullWidth
						>sim</Button>
						<Button
							variant="contained"
							onClick={() => setShow(false)}
							color="error"
							fullWidth
						>não</Button>
					</div>
				</Card>
			</Backdrop>
		</div>
	</Zoom>
}

function CancelAcerto({ open, onClose }) {
	const { id } = useParams()
	const { dados } = useContext(ContextoGeral)

	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [opt, setOpt] = useState("")

	async function onSubmit() {
		setLoading(true)
		setSuccess(false)
		ServicoAPI.cancelarServicoAcerto(dados, id, opt).then(res => {
			if (res.codigo == 200) {
				setSuccess(true)
				setTimeout(() => onClose(), 2500)
			}
		}).finally(() => {
			setLoading(false)
		})
	}

	return <Backdrop in={open ?? false} onClose={onClose} unmountOnExit>
		<Card className="p-3 w-75">
			<h5>Cancelar este serviço?</h5>
			<FormControl fullWidth>
				<InputLabel>Selecione uma ação</InputLabel>
				<Select label="Selecione uma ação" value={opt} onChange={(e, v) => setOpt(e.target.value)} required fullWidth>
					<MenuItem value="" disabled></MenuItem>
					<MenuItem value="CS">Cancelar de vez o serviço.</MenuItem>
					<MenuItem value="RS">Reabrir para outros executarem.</MenuItem>
					<MenuItem value="DSP">Deixar o serviço sem profissional.</MenuItem>
				</Select>
			</FormControl>
			<Collapse in={opt != ""}>
				<div className="mt-2 d-flex gap-3">
					<Button
						onClick={onSubmit}
						variant="contained"
						color="success"
						fullWidth
					>Sim</Button>
					<Button
						onClick={onClose}
						variant="contained"
						color="error"
						fullWidth
					>Não</Button>
				</div>
			</Collapse>
			<Collapse in={loading}>
				<div className="mt-3 text-center">
					<CircularProgress />
				</div>
			</Collapse>
			<Collapse in={success}>
				<Alert severity="success" className="mt-3">
					Sucesso! Aguarde
				</Alert>
			</Collapse>
		</Card>
	</Backdrop>
}


const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
			theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity,
				),
			},
		},
	},
}));

function TelMenu({ tel }) {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const handleClick = e => setAnchorEl(e.currentTarget)
	const handleClose = () => setAnchorEl(null)

	return <div>
		<div
			id="demo-customized-button"
			aria-controls={open ? 'demo-customized-menu' : undefined}
			aria-haspopup="true"
			aria-expanded={open ? 'true' : undefined}
			variant="contained"
			disableElevation
			onClick={handleClick}
			className='fw800'
			endIcon={<KeyboardArrowDownIcon />}
		>
			{tel}
		</div>
		<StyledMenu
			id="demo-customized-menu"
			MenuListProps={{
				'aria-labelledby': 'demo-customized-button',
			}}
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
		>
			<MenuItem onClick={handleClose} disableRipple>
				<CopyAll />
				Copiar
			</MenuItem>
			<MenuItem onClick={() => chamarTel(servico['so.telFixo'])} disableRipple>
				<Call />
				Chamar
			</MenuItem>
			<MenuItem onClick={() => chamarWhats(servico['so.telFixo'])} disableRipple>
				<WhatsApp />
				Whatsapp
			</MenuItem>
		</StyledMenu>
	</div>
}